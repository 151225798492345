import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { apisService } from '../../services/apiservice';
import { Toast } from 'primereact/toast';

const NewsSection = () => {
    const [newsText, setNewsText] = useState('');
    const [newsImage, setNewsImage] = useState(null);
    const toast = React.useRef(null);

    // const handleNewsSubmit = () => {
    //     console.log('News Text:', newsText);
    //     console.log('News Image:', newsImage);
    //     // Add backend API call here
    // };

    const handleFileUpload = (event) => {
        const file = event.files[0];
        if (file) {
            setNewsImage(file); // Save the actual file
            console.log('Uploaded Image File:', file);
        }
    };

    const handleNewsSubmit = async () => {
        const currentDate = new Date();
        const isoFormatDate = currentDate.toISOString();

        if (!newsImage) {
            toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please upload an image before submitting.',
                life: 3000,
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append('date', isoFormatDate);
            formData.append('description', newsText);
            formData.append('image', newsImage);

            console.log("FormData Content:");
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            const response = await apisService.postNews(formData);
            console.log("API Response:", response);

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Your News is Posted!',
                life: 3000,
            });
        } catch (err) {
            console.error('Error while submitting news:', err);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Your News is not posted.',
                life: 3000,
            });
        }
    };



    return (
        <div className="card p-4">
            <Toast ref={toast} />
            <h4>Post Any News here</h4>
            <InputTextarea
                value={newsText}
                onChange={(e) => setNewsText(e.target.value)}
                rows={8}
                cols={80}
                placeholder="Write your news content here..."
                className="w-100 mb-3"
            />
            <div className='col-md-10'>
                <FileUpload
                    mode="basic"
                    name="demo[]"
                    accept="image/*"
                    maxFileSize={1000000}
                    auto
                    customUpload={true}
                    uploadHandler={handleFileUpload}
                    chooseLabel="Upload Image"
                    className="mb-3"
                />
                {newsImage && (
                    <div className="mb-3">
                        <h6>Uploaded Image:</h6>
                        <img src={newsImage} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                    </div>
                )}
                <Button
                    label="Submit News"
                    icon="pi pi-send"
                    className="p-button-success float-right"
                    onClick={handleNewsSubmit}
                />
            </div>

        </div>
    );
};

export default NewsSection;
