import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { apisService } from '../../services/apiservice';
import './kyc.css'; // Add your custom styles here for further UI improvements
import { Dropdown } from 'primereact/dropdown';

const Kyc = () => {
    const [kyc, setKyc] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [filters, setFilters] = useState({
        firstName: { value: null, matchMode: 'contains' },
        lastName: { value: null, matchMode: 'contains' },
        country: { value: null, matchMode: 'contains' },
        type: { value: null, matchMode: 'contains' },
        id: { value: null, matchMode: 'equals' },
        status: { value: null, matchMode: 'contains' },
    });

    useEffect(() => {
        getKyc();
    }, []);

    const getKyc = async () => {
        try {
            const res = await apisService.getKycAdmin();
            setKyc(res?.data || []);
        } catch (error) {
            console.error("Failed to fetch KYC data", error);
        }
    };

    const showDetails = (user) => {
        setSelectedUser(user);
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        setSelectedUser(null);
    };
    const handleStatusChange = async (rowData, newStatus) => {
        try {
            const payload = {
                // id: rowData.id,
                status: newStatus,
                reason: 'string',
            };
            await apisService.updateKycStatus(rowData?.id, payload); // Call the update API with payload
            getKyc(); // Refresh the data after status update
        } catch (error) {
            console.error('Failed to update status:', error);
        }
    };
    const statusOptions = [
        { label: 'Pending', value: 'Pending' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Approved', value: 'Approved' },
    ];
    const statusTemplate = (rowData) => (
        <Dropdown
            value={rowData.status}
            options={statusOptions}
            onChange={(e) => handleStatusChange(rowData, e.value)}
            placeholder="Select Status"
            className="status-dropdown"
        />
    );

    // const header = (
    //     <div className="table-header">
    //         <h2>KYC Management</h2>
    //         <span className="table-subtitle">Manage and review user KYC requests below</span>
    //     </div>
    // );

    return (
        <div className="kyc-page">
            <div className="datatable-container">
                <DataTable
                    value={kyc}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 20]}
                    className="p-datatable-gridlines"
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    // header={header}
                    responsiveLayout="scroll"
                >
                    <Column
                        field="firstName"
                        header="First Name"
                        // sortable
                        filter
                        // filterPlaceholder="Search by First Name"
                        filterField="firstName"
                    />
                    <Column
                        field="lastName"
                        header="Last Name"
                        // sortable
                        filter
                        // filterPlaceholder="Search by Last Name"
                        filterField="lastName"
                    />
                    <Column
                        field="country"
                        header="Country"
                        // sortable
                        filter
                        // filterPlaceholder="Search by Country"
                        filterField="country"
                    />
                    <Column
                        field="type"
                        header="KYC Type"
                        // sortable
                        filter
                        // filterPlaceholder="Search by KYC Type"
                        filterField="type"
                    />
                    <Column
                        field="id"
                        header="ID"
                        // sortable
                        filter
                        // filterPlaceholder="Search by ID"
                        filterField="id"
                    />
                    <Column
                        field="status"
                        header="Status"
                        body={statusTemplate}
                        // sortable
                    />
                    <Column
                        header="Actions"
                        body={(rowData) => (
                            <Button
                                label="Details"
                                icon="pi pi-info-circle"
                                onClick={() => showDetails(rowData)}
                                className="p-button-text"
                            />
                        )}
                    />
                </DataTable>
            </div>

            {/* Dialog for User Details */}
            <Dialog
                visible={isDialogVisible}
                onHide={hideDialog}
                header="User Details"
                className="kyc-dialog"
            >
                {selectedUser ? (
                    <div>
                        <p>
                            <strong>Name:</strong> {selectedUser.firstName} {selectedUser.lastName}
                        </p>
                        <p>
                            <strong>Email:</strong> {selectedUser.email}
                        </p>
                        <p>
                            <strong>Country:</strong> {selectedUser.country}
                        </p>
                        <p>
                            <strong>KYC Type:</strong> {selectedUser.type}
                        </p>
                        <p>
                            <strong>Status:</strong> {selectedUser.status}
                        </p>
                    </div>
                ) : (
                    <p>No user selected.</p>
                )}
            </Dialog>
        </div>
    );
};

export default Kyc;
