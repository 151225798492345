import React from 'react';
import './Dashboard.css';
import { Card } from 'primereact/card';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            <div className="dashboard-cards">
                <Card title="Total Balance (USDT)">
                    <h2>$25,000</h2>
                </Card>
                <Card title="Total Withdrawals (USDT)">
                    <h2>$15,000</h2>
                </Card>
                <Card title="Total Deposits (USDT)">
                    <h2>$40,000</h2>
                </Card>
            </div>
        </div>
    );
};

export default Dashboard;
