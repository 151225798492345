import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './index.css'; // Import the CSS file for styling
import { Toast } from 'primereact/toast';
import { apisService } from "../../services/apiservice";

const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const toast = React.useRef(null);

    // const handleLogin = async (e) => {
    //     e.preventDefault();
    //     props.onLogin();
    //     navigate("/dashboard")
    //     // try {
    //     //     // Replace with your actual login API endpoint
    //     //     const response = await axios.post("/api/login", {
    //     //         username,
    //     //         password
    //     //     });

    //     //     if (response.data.token) {
    //     //         localStorage.setItem("authToken", response.data.token);
    //     //         props.onLogin();
    //     //         navigate("/dashboard"); // Redirect to home page after login
    //     //     } else {
    //     //         setError("Invalid login credentials");
    //     //         navigate("/dashboard");
    //     //     }
    //     // } catch (err) {
    //     //     navigate("/dashboard");
    //     //     setError("An error occurred during login. Please try again.");
    //     // }
    // };
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter both email and password', life: 3000 });
            return;
        }
        let email = username
        setLoading(true);
        const payload = { email, password };

        try {
            const response = await apisService.loginApi(payload);
            const { accessToken, refreshToken, message } = response;

            // Save tokens
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
            props.onLogin();
            navigate('/dashboard');
        } catch (error) {
            console.error("Error during login:", error.response?.data || error.message);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.message || 'Login failed', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <Toast ref={toast} />
                <h2>Log in</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            // required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            // required
                        />
                    </div>
                    <button type="submit" className="login-button">Log in</button>
                    {/* <div className="form-options">
                        <label>
                            <input type="checkbox" /> Remember me
                        </label>
                        <a href="/forgot-password">Forgot Password?</a>
                    </div> */}
                    {/* <a href="/create-account" className="create-account">Create an Account</a> */}
                </form>
            </div>
        </div>
    );
};

export default Login;
