import React, { useEffect, useState } from 'react';
import './Users.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { apisService } from '../../services/apiservice';

const Users = () => {
    // const [users] = useState([
    //     { id: 1, name: 'John Doe', email: 'john@example.com', balance: 120 },
    //     { id: 2, name: 'Jane Doe', email: 'jane@example.com', balance: 200 },
    // ]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [filters, setFilters] = useState({
        firstName: { value: null, matchMode: 'contains' },
        lastName: { value: null, matchMode: 'contains' },
        country: { value: null, matchMode: 'contains' },
        type: { value: null, matchMode: 'contains' },
        id: { value: null, matchMode: 'equals' },
        status: { value: null, matchMode: 'contains' },
    });

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            const res = await apisService.getUsersAdmin();
            setUsers(res?.data || []);
        } catch (error) {
            console.error("Failed to fetch KYC data", error);
        }
    };


    const showDetails = (user) => {
        setSelectedUser(user);
        setDialogVisible(true);
    };

    return (
        <div className="users">
            <h1>Users</h1>
            <DataTable value={users} paginator rows={10} className="p-datatable-gridlines">
                <Column field="id" header="ID"  />
                <Column field="userName" header="User Name"  />
                <Column field="firstName" header="First Name"  />
                <Column field="lastName" header="Last Name"  />
                <Column field="email" header="Email"  />
                <Column field="kycStatus" header="Kyc Status"  />
                {/* <Column field="isDisabled" header="isDisabled"  /> */}
                {/* <Column
                    header="Actions"
                    body={(rowData) => (
                        <button onClick={() => showDetails(rowData)}>Details</button>
                    )}
                /> */}
            </DataTable>

            <Dialog visible={isDialogVisible} onHide={() => setDialogVisible(false)} header="User Details">
                {selectedUser && (
                    <div>
                        <p>Name: {selectedUser.name}</p>
                        <p>Email: {selectedUser.email}</p>
                        <p>Balance: {selectedUser.balance}</p>
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default Users;
