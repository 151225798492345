import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faMoneyCheckAlt, faWallet, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ logout }) => {
    const [selected, setSelected] = useState('dashboard');

    const handleItemClick = (item) => {
        setSelected(item);
    };

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <h2>Bitx Admin</h2>
            </div>
            <ul className="sidebar-menu">
                <li
                    className={selected === 'dashboard' ? 'active' : ''}
                    onClick={() => handleItemClick('dashboard')}
                >
                    <Link to="/dashboard">
                        <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Dashboard
                    </Link>
                </li>
                <li
                    className={selected === 'users' ? 'active' : ''}
                    onClick={() => handleItemClick('users')}
                >
                    <Link to="/users">
                        <FontAwesomeIcon icon={faUsers} className="fa-icon" /> Users
                    </Link>
                </li>
                <li
                    className={selected === 'kyc' ? 'active' : ''}
                    onClick={() => handleItemClick('kyc')}
                >
                    <Link to="/kyc">
                        <FontAwesomeIcon icon={faMoneyCheckAlt} className="fa-icon" /> KYC
                    </Link>
                </li>
                <li
                    className={selected === 'announce' ? 'active' : ''}
                    onClick={() => handleItemClick('announce')}
                >
                    <Link to="/announce">
                        <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Post Announcements
                    </Link>
                </li>
                <li
                    className={selected === 'news' ? 'active' : ''}
                    onClick={() => handleItemClick('news')}
                >
                    <Link to="/news">
                        <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Post News
                    </Link>
                </li>
                <li
                    className={selected === 'pending-withdrawals' ? 'active' : ''}
                    onClick={() => handleItemClick('pending-withdrawals')}
                >
                    <Link to="/pending-withdrawals">
                        <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Pending Withdrawals
                    </Link>
                </li>
                <li
                    className={selected === 'deposits' ? 'active' : ''}
                    onClick={() => handleItemClick('deposits')}
                >
                    <Link to="/deposits">
                        <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Deposits
                    </Link>
                </li>

                <li
                    className={selected === 'settings' ? 'active' : ''}
                    onClick={() => handleItemClick('settings')}
                >
                    <Link to="/settings">
                        <FontAwesomeIcon icon={faCogs} className="fa-icon" /> Settings
                    </Link>
                </li>
                <li
                    className={selected === 'logout' ? 'active' : ''}
                    onClick={() => {
                        handleItemClick('logout');
                        logout();
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon" /> Logout
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;










// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './sidebar.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUsers, faMoneyCheckAlt, faWallet, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

// const Sidebar = ({ logout }) => {
//     return (
//         <div className="sidebar">
//             <div className="sidebar-header">
//                 <h2>Bitx Admin</h2>
//             </div>
//             <ul className="sidebar-menu">
//                 <li><Link to="/dashboard"><FontAwesomeIcon icon={faWallet} /> Dashboard</Link></li>
//                 <li><Link to="/users"><FontAwesomeIcon icon={faUsers} /> Users</Link></li>
//                 <li><Link to="/kyc"><FontAwesomeIcon icon={faMoneyCheckAlt} /> KYC</Link></li>
//                 <li><Link to="/pending-withdrawals"><FontAwesomeIcon icon={faWallet} /> Pending Withdrawals</Link></li>
//                 <li><Link to="/deposits"><FontAwesomeIcon icon={faWallet} /> Deposits</Link></li>
//                 <li><Link to="/settings"><FontAwesomeIcon icon={faCogs} /> Settings</Link></li>
//                 <li onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</li>
//             </ul>
//         </div>
//     );
// };

// export default Sidebar;
