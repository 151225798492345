import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { apisService } from '../../services/apiservice';
import { Toast } from 'primereact/toast';

const AnnounceSection = () => {
    const [announceText, setAnnounceText] = useState('');
    const toast = React.useRef(null);


    const handleAnnounceSubmit = async () => {
        const currentDate = new Date(); // Example: Sun Nov 24 2024 01:26:50 GMT+0500
        const isoFormatDate = currentDate.toISOString();
        const payload = {
            date: isoFormatDate,
            description: announceText,
        }
        console.log("payload is ", payload);
        try {
            const res = await apisService.postAnnouncement(payload);
            console.log("announce post data is ", res);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Your Announcement is Posted!", life: 3000 });

            // setUsers(res?.data || []);
        } catch (error) {
            console.error("Failed to fetch KYC data", error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Your Announcement is not posted', life: 3000 });

        }
    };

    return (
        <>
            <Toast ref={toast} />

            <div className="card p-4">
                <h4>Announce Something</h4>
                <InputTextarea
                    value={announceText}
                    onChange={(e) => setAnnounceText(e.target.value)}
                    rows={8}
                    cols={80}
                    placeholder="Write your announcement here..."
                    className="w-100 mb-3"
                />

            </div>
            <div className='col-md-5'>
                <Button
                    label="Submit Announcement"
                    icon="pi pi-check"
                    className="p-button-primary w-100"
                    onClick={handleAnnounceSubmit}
                />
            </div>
        </>
    );
};

export default AnnounceSection;
