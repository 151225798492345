import axios, { AxiosResponse } from 'axios';


const baseURL = `http://ec2-54-161-54-218.compute-1.amazonaws.com:3000`;
// const baseURL = `${DetectEnvironment.config.REACT_APP_API_BASE_URL}/ChatAppApi/api`;

const api = axios.create({
    baseURL,
    timeout: 80000, // Timeout after 10 seconds
    headers: {
        'Content-Type': 'application/json',
        accept: "application/json",
    },
});

// getting token
const getToken = () => {
    // let userDetails = await AsyncStorage.getItem("USER_DATA");
    let token = localStorage.getItem("ACCESS_TOKEN");
    // console.log("hi token", token)
    return token || null; // Return the token or null if not found

    // let token = JSON.parse(userDetails);
    // // const userDetails = await userService.GetUserData();
    // console.log("token in api is ", token)
    // return token?.accessToken || null;
};

// adding token if available
api.interceptors.request.use(
    async (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// handling responses here
api.interceptors.response.use(
    response => {
        // console.log("Response in api manager: ", response.data);
        return response.data;
    },
    error => {
        if (error.response) {
            console.log('Response Error:', error.response);
            return Promise.reject(error.response.data);
        } else if (error.request) {
            console.log('Request Error:', error.request);
            return Promise.reject('Network error, please try again.');
        } else {
            console.log('Error:', error.message);
            return Promise.reject(error.message);
        }
    }
);
export default api;

export const getIpAddress = async () => {
    const res = await axios?.get("https://geolocation-db.com/json/");
    return res?.data?.IPv4;
};