import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from '../src/component/sidebar/sidebar';
import Dashboard from '../src/component/Dashboard/Dashboard';
import Users from '../src/component/Users/Users'
import Login from './component/login';
import Kyc from './component/kyc/kyc';
import AnnounceNews from './component/postScreens/announceScreen';
import AnnounceSection from './component/postScreens/announceScreen';
import NewsSection from './component/postScreens/newsScreen';
import "../src/App.css"

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('ACCESS_TOKEN'));
  useEffect(() => {
    // Check for token in localStorage on initial load
    const token = localStorage.getItem('ACCESS_TOKEN');
    setIsAuthenticated(!!token);
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false); // Update authentication state
    window.location.href = '/login';
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
  };
  const DashboardLayout = ({ children, logout }) => {
    return (
      <div className="d-flex">
        <Sidebar logout={logout} />
        <div className="content-container">{children}</div>
      </div>
      /* <div className="flex-grow-1">
        <TopNavBar />
       
      </div> */
      // </div>
    );
  };
  const AuthLayout = ({ children }) => {
    return <div className="auth-container">{children}</div>;
  };
  console.log("isAuthenticated", isAuthenticated);
  return (
    <Router>
      {/* <Sidebar onLogout={handleLogout} /> */}
      <Routes>
        {isAuthenticated ? (
          <Route
            path="*"
            element={
              <DashboardLayout logout={handleLogout}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/kyc" element={<Kyc />} />
                  <Route path="/announce" element={<AnnounceSection />} />
                  <Route path="/news" element={<NewsSection />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </DashboardLayout>
            }
          />
        ) : (
          <Route
            path="*"
            element={
              <AuthLayout>
                <Routes>
                  <Route path="/" element={<Login onLogin={handleLogin} />} />
                  <Route path="/login" element={<Login onLogin={handleLogin} />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </AuthLayout>
            }
          />
        )}

        {/* Add other routes */}
      </Routes>
    </Router>
  );
};

export default App;















// // App.js
// import React, { useEffect } from "react";
// import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
// import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, AppBar } from "@material-ui/core";
// import { Home as HomeIcon, SimCardTwoTone, SimCard, NoSim } from "@material-ui/icons";
// import "primereact/resources/themes/saga-blue/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
// import "./App.css";
// import ActiveSims from "./component/activeSims";
// import InActiveSims from "./component/inactiveSims";
// import Sims from "./component/home";
// import SimDetail from "./component/simDetail";
// import Dashboard from "./component/dashboard";
// import Login from "./component/login";

// const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex"
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0
//   },
//   drawerPaper: {
//     width: drawerWidth
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3)
//   }
// }));


// const App = () => {
//   const classes = useStyles();

//   const urlPath = window.location.pathname;
//   const lastSegment = urlPath.substring(urlPath.lastIndexOf('/') + 1);
//   console.log("Last segment of URL:", lastSegment);

//   return (
//     <div className={classes.root}>
//       <AppBar position="fixed" style={{ backgroundColor: "gray" }} className={classes.appBar}>
//         <Toolbar>
//           <Typography variant="h6" noWrap>
//             Bitx Admin
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Router>
//         {lastSegment && <Drawer
//           className={classes.drawer}
//           variant="permanent"
//           classes={{
//             paper: classes.drawerPaper
//           }}
//         >
//           <Toolbar />
//           <div className={classes.drawerContainer}>
//             <List>
//               <ListItem button component={Link} to="/">
//                 <ListItemIcon><HomeIcon /></ListItemIcon>
//                 <ListItemText primary="Home" />
//               </ListItem>
//               <ListItem button component={Link} to="/sims">
//                 <ListItemIcon><SimCardTwoTone /></ListItemIcon>
//                 <ListItemText primary="Sims" />
//               </ListItem>
//               <ListItem button component={Link} to="/sims/active">
//                 <ListItemIcon><SimCard /></ListItemIcon>
//                 <ListItemText primary="Active Sims" />
//               </ListItem>
//               <ListItem button component={Link} to="/sims/inactive">
//                 <ListItemIcon><NoSim /></ListItemIcon>
//                 <ListItemText primary="InActive Sims" />
//               </ListItem>
//             </List>
//           </div>
//         </Drawer>}
//         <main className={classes.content}>
//           <Toolbar />
//           <Routes>
//             <Route path="/" element={<Login />} />
//             <Route path="/dashboard" element={<Dashboard />} />
//             <Route path="/sims" element={<Sims />} />
//             <Route path="/sims/active" element={<ActiveSims />} />
//             <Route path="/sims/inactive" element={<InActiveSims />} />
//             <Route path="/sim-detail" element={<SimDetail />} />
//           </Routes>
//         </main>
//       </Router>
//     </div>
//   );
// };


// export default App;